import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { apirc } from '~/configs/apirc';
import useSWR from 'swr-0-5-6';
import urlcat from 'urlcat';
import dayAPI from '~/utils/dayAPI';
import { useSnapshot } from 'valtio';
import { freemanStore } from './component/VideoContnet';
import { MonthContractKbar } from './component/stockbarBoxView/MonthContractKbar';
import { dailyAmplitudeStatsCalculator } from './component/dailyAmplitudeStatsCalculator';
import { PriceLevelItem } from './component/PriceLevelItem';
const ROOT_URL = apirc.tvAPIsOfGCP;
const emptyData = {
    t: [0],
    o: [0],
    h: [0],
    l: [0],
    c: [0],
    v: [0],
    s: 'ok',
};
/** 獲取合約週期報價 */
export const useGetDataInfo = (options) => {
    const { symbol, resolution, from, refresh } = options;
    const url = urlcat(`${ROOT_URL}/history`, { symbol, resolution, from });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        refreshInterval: refresh * 60 * 1000,
    });
    return { data: res.data };
};
const ContractData = memo(function SidePane(props_) {
    const state = useSnapshot(freemanStore);
    const tabState = state.ampTabs;
    //需要改進: 當報價大於合約最低最高 則再次拿取api
    // useEffect(() => {
    //   signalrStore2.addQuote('TXAM-1')
    //   return () => {
    //     signalrStore2.removeQuote('TXAM-1')
    //   }
    // }, [JSON.stringify('TXAM-1')])
    // const value = useSnapshot(signalrStore2.values.quote)['TXAM-1']
    // const high = value?.high
    // const low = value?.low
    //------------------------------------------------
    const symbol = 'TXAM-1';
    const fromMounth = dayAPI().subtract(20, 'month').unix();
    const fromWeek = dayAPI().subtract(20, 'week').unix();
    const fromDay = dayAPI().subtract(20, 'day').unix();
    //------------------------------------------------
    const dataOfDays = useGetDataInfo({ symbol, resolution: 'D', from: fromDay, refresh: 1 }).data ?? emptyData;
    const dataOfWeeks = useGetDataInfo({ symbol, resolution: 'SW', from: fromWeek, refresh: 5 }).data ?? emptyData;
    const dataOfMonths = useGetDataInfo({ symbol, resolution: 'SM', from: fromMounth, refresh: 5 }).data ?? emptyData;
    //------------------------------------------------
    const dataOfDayLenght = dataOfDays.t.length;
    const dataOfweekLenght = dataOfWeeks.t.length;
    const dataOfMonthLenght = dataOfMonths.t.length;
    //------------------------------------------------
    //月合約資訊
    const contractHigh = dataOfMonths?.h[dataOfMonthLenght - 1];
    const contractLow = dataOfMonths?.l[dataOfMonthLenght - 1];
    const contractOpen = dataOfMonths?.o[dataOfMonthLenght - 1];
    const contractClose = dataOfMonths?.c[dataOfMonthLenght - 1];
    const monthCost = (dataOfMonths.h[dataOfMonthLenght - 1] + dataOfMonths.l[dataOfMonthLenght - 1]) / 2;
    //------------------------------------------------
    //本月最低
    const monthLow = dataOfMonths?.l[dataOfMonthLenght - 1];
    //本週最低
    const weekLow = dataOfWeeks?.l[dataOfweekLenght - 1];
    //本日最低
    const dayLow = dataOfDays?.l[dataOfDayLenght - 1];
    //本月最高
    const monthHigh = dataOfMonths?.h[dataOfMonthLenght - 1];
    //本週最高
    const weekHigh = dataOfWeeks?.h[dataOfweekLenght - 1];
    //本日最高
    const dayHigh = dataOfDays?.h[dataOfDayLenght - 1];
    //------------------------------------------------
    const dataOfInterval = () => {
        if (tabState === 'month') {
            return dataOfMonths;
        }
        else if (tabState === 'week') {
            return dataOfWeeks;
        }
        else {
            return dataOfDays;
        }
    };
    const dataLength = () => {
        if (tabState === 'month') {
            return dataOfMonthLenght;
        }
        else if (tabState === 'week') {
            return dataOfweekLenght;
        }
        else {
            return dataOfDayLenght;
        }
    };
    const data = dailyAmplitudeStatsCalculator(dataOfInterval(), dataLength());
    //根據現在選擇'日'、'週'、'月' 返回該週期的最高或最低
    const intervalLow = () => {
        if (tabState === 'month') {
            return monthLow;
        }
        else if (tabState === 'week') {
            return weekLow;
        }
        else {
            return dayLow;
        }
    };
    const intervalHigh = () => {
        if (tabState === 'month') {
            return monthHigh;
        }
        else if (tabState === 'week') {
            return weekHigh;
        }
        else {
            return dayHigh;
        }
    };
    const maxAmp = data.max;
    const maxAvgAmp = data.aboveAverage;
    const avgAmp = data.average;
    const minAvgAmp = data.belowAverage;
    const minAmp = data.min;
    const longLevel5 = intervalLow() + maxAmp;
    const longLevel4 = intervalLow() + maxAvgAmp;
    const longLevel3 = intervalLow() + avgAmp;
    const longLevel2 = intervalLow() + minAvgAmp;
    const longLevel1 = intervalLow() + minAmp;
    const shortLevel1 = intervalHigh() - minAmp;
    const shortLevel2 = intervalHigh() - minAvgAmp;
    const shortLevel3 = intervalHigh() - avgAmp;
    const shortLevel4 = intervalHigh() - maxAvgAmp;
    const shortLevel5 = intervalHigh() - maxAmp;
    const longLevelData = [
        { levelString: '關卡價5', value: longLevel5, color: '#a11100' },
        { levelString: '關卡價4', value: longLevel4, color: '#911100' },
        { levelString: '關卡價3', value: longLevel3, color: '#751100' },
        { levelString: '關卡價2', value: longLevel2, color: '#651100' },
        { levelString: '關卡價1', value: longLevel1, color: '#551100' },
        { levelString: '最低價', value: intervalLow(), color: '#363839' },
    ];
    const shortLevelData = [
        { levelString: '最高價', value: intervalHigh(), color: '#363839' },
        { levelString: '關卡價1', value: shortLevel1, color: '#115500' },
        { levelString: '關卡價2', value: shortLevel2, color: '#116500' },
        { levelString: '關卡價3', value: shortLevel3, color: '#117500' },
        { levelString: '關卡價4', value: shortLevel4, color: '#119100' },
        { levelString: '關卡價5', value: shortLevel5, color: '#11a100' },
    ];
    const contractPriceData = [
        { levelString: '合約開', value: contractOpen, color: '#00000000' },
        { levelString: '合約高', value: contractHigh, color: '#00000000' },
        { levelString: '合約低', value: contractLow, color: '#00000000' },
        { levelString: '月成本', value: monthCost, color: '#00000000' },
    ];
    const ampData = [
        { levelString: '最大振幅', value: maxAmp, color: '#585e61' },
        { levelString: '大大振幅', value: maxAvgAmp, color: '#585e61' },
        { levelString: '平均振幅', value: avgAmp, color: '#585e61' },
        { levelString: '小小振幅', value: minAvgAmp, color: '#585e61' },
        { levelString: '最小振幅', value: minAmp, color: '#585e61' },
    ];
    //
    const intervalString = () => {
        if (tabState === 'month') {
            return '月';
        }
        else if (tabState === 'week') {
            return '週';
        }
        else
            return '日';
    };
    const monthData = dailyAmplitudeStatsCalculator(dataOfMonths, dataOfMonthLenght);
    const contractKbarData = {
        open: contractOpen,
        high: contractHigh,
        low: contractLow,
        close: contractClose,
        averRange: monthData.average,
        maxRange: monthData.max,
    };
    return (<div css={classes.contractData.container}>
      <div css={css `
          ${classes.title};
          background-color: #4a4a4a;
        `}>
        台指合約相關資訊
      </div>
      <div css={classes.contractData.kbarInfo}>
        <div>
          <PriceLevelItem data={contractPriceData}/>
        </div>
        <MonthContractKbar contractData={contractKbarData}/>
      </div>
      {props_.children}
      <div css={css `
          ${classes.title};
          background-color: #952c2c;
        `}>
        多方關卡價
      </div>
      <div css={css `
          ${classes.priceLevel.container};
          gap: 1px;
        `}>
        <PriceLevelItem data={longLevelData}/>
      </div>
      <div css={css `
          ${classes.title};
          background-color: #2c952c;
        `}>
        空方關卡價
      </div>
      <div css={css `
          ${classes.priceLevel.container};
          gap: 1px;
        `}>
        <PriceLevelItem data={shortLevelData}/>
      </div>
      <div css={css `
          ${classes.title};
          background-color: #4a4a4a;
        `}>
        近20{intervalString()}-{intervalString()}振幅統計
      </div>
      <div css={css `
          ${classes.priceLevel.container};
          gap: 1px;
        `}>
        <PriceLevelItem data={ampData}/>
      </div>
    </div>);
});
/** 切換週期的案鈕群組 */
const IntervalSwitch = memo(function IntervalSwitch() {
    const state = useSnapshot(freemanStore);
    const tabState = state.ampTabs;
    return (<div css={css `
        ${fill_horizontal_all_center};
        height: 30px;
        background-color: #353535;
        gap: 8px;
      `}>
      <div css={tabState === 'day' ? classes.switchButtomSelected : classes.switchButtom} onClick={() => (freemanStore.ampTabs = 'day')}>
        日振幅
      </div>
      <div css={tabState === 'week' ? classes.switchButtomSelected : classes.switchButtom} onClick={() => (freemanStore.ampTabs = 'week')}>
        週振幅
      </div>
      <div css={tabState === 'month' ? classes.switchButtomSelected : classes.switchButtom} onClick={() => (freemanStore.ampTabs = 'month')}>
        月振幅
      </div>
    </div>);
});
export const Freeman_SidePane2 = memo(function Freeman_SidePane2() {
    return (<div css={classes.container}>
      <ContractData>
        <IntervalSwitch />
      </ContractData>
    </div>);
});
const classes = {
    container: css `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    background-color: #151515;
    border-radius: 4px;
    user-select: none;
  `,
    switchButtom: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #232323;
    color: #aaaaaa;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
    switchButtomSelected: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #e48100;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
    title: css `
    ${fill_vertical_all_center};
    height: 24px;

    border-radius: 4px;
    padding: 0 4px;
    font-size: 14px;
    font-weight: bold;
  `,
    contractData: {
        container: css `
      ${fill_vertical_cross_center};
      gap: 4px;
      font-size: 14px;
    `,
        kbarInfo: css `
      width: 100%;
      display: grid;
      grid-template-columns: 68% 32%;
      background-color: #1e1d1d;
      border-radius: 4px;
      padding: 4px;
      border: 1px solid #888888;
    `,
    },
    contractInfo: {
        container: css `
      ${fill_vertical_all_center};
      background-color: #1e1d1d;
      height: 132px;
      padding: 4px;
      border-radius: 4px;
      border: 1px solid #888888;
    `,
    },
    priceLevel: {
        container: css `
      ${fill_vertical_all_center};
      height: auto;
      padding: 4px;
      border-radius: 4px;
      border: 1px solid #888888;
    `,
    },
    ampTitle: css `
    ${fill_vertical_all_center};
    height: 24px;
    background-color: #4a4a4a;
    border-radius: 4px;
    padding: 0 4px;
    font-size: 14px;
    font-weight: bold;
  `,
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
