import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SignalWall } from './component/page-view/SignalWall';
import { useSnapshot } from 'valtio';
import { investment_store } from './investment_SidePaneMenu';
import { ScreenerBase } from './component/page-view/ScreenerBase';
import { OptionAnalytics } from './component/page-view/OptionAnalytics';
import { OptionQuote } from './component/page-view/OptionQuote';
import { TraderVip } from './component/page-view/TraderVip';
import { MarketView } from './component/page-view/MarketView';
export const Investment_View = memo(function Investment_View() {
    const theme = useThemeStore(s => s.theme);
    const storeState = useSnapshot(investment_store);
    return (<classes.container className={theme}>
      {storeState.pageView === 'market-view' && <MarketView />}
      {storeState.pageView === 'signal-wall' && <SignalWall />}
      {storeState.pageView === 'screener-base' && <ScreenerBase />}
      {storeState.pageView === 'option-opbs' && <OptionAnalytics />}
      {storeState.pageView === 'option-quote' && <OptionQuote />}
      {storeState.pageView === 'vip-trader' && <TraderVip />}
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 18px;
    padding: 2px;
    height: 100%;
    &.dark {
      background-color: #151515;
      color: #ffffff;
    }
    &.light {
      background-color: #ffffff;
      color: #151515;
    }
  `,
    pane: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 8px;
    padding: 4px;
    width: 336px;
  `,
    item: styled.div `
    ${fill_vertical_all_center};
    height: auto;
    border-radius: 4px;
    gap: 2px;
    padding: 2px;
    //box-shadow: 0 0 4px 2px #dedede;
  `,
};
const classCss = {
    baseWidth: css `
    width: 336px;
  `,
    baseHeight: css `
    height: 40px;
  `,
    baseFill: css `
    height: 40px;
    width: 336px;
  `,
    chartHeight: css `
    min-height: 200px;
  `,
};
