import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import styled from '@emotion/styled';
import { proxy } from 'valtio';
export const investment_store = proxy({
    pageView: 'signal-wall',
});
//todo: 是否需要使用 盤中/盤後分類
export const Investment_SidePaneMenu = memo(function Investment_SidePaneMenu() {
    return (<classes.container>
      <classes.linkItem>大盤概況</classes.linkItem>
      {/* 加權走勢圖 上櫃走勢圖 個股排行榜 點數貢獻 */}
      <classes.link onClick={() => (investment_store.pageView = 'market-view')}>
        -綜合報價
      </classes.link>
      <classes.linkItem>權值股</classes.linkItem>
      {/* K棒群組 權重磚塊 */}
      <classes.link>-權值股監控</classes.link>
      {/* 報價列 技術分析 */}
      <classes.link>-權值股看盤</classes.link>
      <classes.linkItem>期貨</classes.linkItem>
      <classes.link onClick={() => (investment_store.pageView = 'vip-trader')}>
        -VIP看盤室
      </classes.link>
      <classes.linkItem>選擇權</classes.linkItem>
      <classes.link onClick={() => (investment_store.pageView = 'option-quote')}>
        -選擇權報價
      </classes.link>
      <classes.link onClick={() => (investment_store.pageView = 'option-opbs')}>
        -選擇權分析
      </classes.link>
      <classes.linkItem>大盤籌碼</classes.linkItem>
      <classes.linkItem>營收追蹤</classes.linkItem>
      <classes.link onClick={() => (investment_store.pageView = 'realtime-revenue')}>
        -即時營收
      </classes.link>
      <classes.link>-營收排行</classes.link>
      <classes.linkItem>選股策略</classes.linkItem>
      <classes.link onClick={() => (investment_store.pageView = 'screener-base')}>
        -快速選股
      </classes.link>
      <classes.linkItem>即時監控</classes.linkItem>
      <classes.link onClick={() => (investment_store.pageView = 'signal-wall')}>
        -動向監控
      </classes.link>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    align-items: start;
    background-color: #252525;
    padding: 2px;
    gap: 4px;
    border-right: 1px solid #777777;
    font-size: 14px;
  `,
    linkItem: styled.div `
    color: #999999;
    font-weight: 700;
  `,
    link: styled.div `
    color: #eeeeee;
    font-size: 13px;
    margin-left: 8px;
    cursor: pointer;
  `,
};
