import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Freeman_TopBar } from './freeman_TopBar';
import { Freeman_SidePane1 } from './freeman_SidePane1';
import { Freeman_Row } from './freeman_Row';
import { freeman_initStrategies } from './freeman_initStrategies';
import { Freeman_SidePane2 } from './freeman_SidePane2';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
//import './component/StockKbarBox.css'
export const freeman_init = (templateProps) => {
    freeman_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode(useThemeStore.getState().theme);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.downColor': '#429488',
        'mainSeriesProperties.candleStyle.upColor': '#e15241',
        'mainSeriesProperties.candleStyle.wickDownColor': '#dedede',
        'mainSeriesProperties.candleStyle.wickUpColor': '#dedede',
        'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': false,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#2f2f2f',
        'paneProperties.horzGridProperties.color': '#2f2f2f',
        'paneProperties.backgroundType': 'gradient',
        'paneProperties.backgroundGradientStartColor': '#141721',
        'paneProperties.backgroundGradientEndColor': '#141721',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 12,
        'scalesProperties.textColor': '#dedede',
        'scalesProperties.lineColor': '#dedede',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TXAM-1',
        interval: '1',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: true,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
        overrides: {
            ...store.charting.darkOverrides,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr 336px;
    grid-template-columns: 280px 1fr 464px;
    place-content: flex-start;

    .Chart {
    }

    grid-template-areas:
      'Drawer2 Row1 Drawer1'
      'Drawer2 Chart Drawer1'
      'Drawer2 Row2 Drawer1';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr 336px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Row2 Row2'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr 336px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Row2 Row2'
        'Chart Chart';
    `)}
  `;
    //templateProps.layout.login = Edwin_LoginView
    //templateProps.layout.Charting = undefined
    templateProps.layout.Row1 = <Freeman_TopBar />;
    templateProps.layout.Row2 = <Freeman_Row />;
    templateProps.layout.Drawer1 = <Freeman_SidePane1 />;
    templateProps.layout.Drawer2 = <Freeman_SidePane2 />;
    templateProps.hooks.add(useSignalrStart2_1);
};
